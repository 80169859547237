<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Ocorrências</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Tipos de
                  <span>ocorrência</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-2">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista de ocorrências
                          </h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                          <button
                            class="btn btn-sm btn-primary"
                            @click.prevent="showModal('modalCriarTipoOcorrencia')"
                          >
                            <small>+ Criar ocorrência</small>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr class="text-center">
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Nome da ocorrência</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastSecretariaTipoOcorrenciasLoading">
                              <tr>
                                <td
                                  colspan="2"
                                  class="text-center"
                                  v-html="fastLoadingDados()"
                                />
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastSecretariaTipoOcorrencias.length">
                              <tr
                                v-for="ocorrencia in fastSecretariaTipoOcorrencias"
                                :key="ocorrencia.id_tipo_ocorrencia"
                              >
                                <td class="align-middle text-center">
                                  {{ ocorrencia.nome_ocorrencia }}
                                </td>                       
                                <td class="text-center align-middle">                                  
                                  <button
                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                    @click="exibeModalEditarTipoOcorrencia(ocorrencia)"
                                  >
                                    <small>Editar</small>
                                  </button>
                                  <button
                                    class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                    @click="exibeModalExcluirTipoOcorrencia(ocorrencia)"
                                  >
                                    <small>Excluir</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="2"
                                  class="text-center"
                                >
                                  Nenhum ocorrência cadastrado
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarTipoOcorrencia"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo ocorrencia</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarTipoOcorrencia')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Nome da ocorrência</label>
              <input
                v-model="fastSecretariaTipoOcorrenciaNovo.nome_ocorrencia"
                type="text"
                class="form-control"
                maxlength="100"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Observações</label>
              <textarea
                v-model="fastSecretariaTipoOcorrenciaNovo.obs"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2 text-center">
              <label>Aceita anexo</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-model="fastSecretariaTipoOcorrenciaNovo.aceita_anexo"
                        checked="checked"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2 text-center">
              <label>Notifica responsável</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-model="fastSecretariaTipoOcorrenciaNovo.notifica_responsavel"
                        checked="checked"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarSecretariaTipoOcorrencia()"
                >
                  Criar ocorrência
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarTipoOcorrencia"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar ocorrência</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarTipoOcorrencia')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Nome do ocorrência</label>
              <input
                v-model="fastSecretariaTipoOcorrenciaEditar.nome_ocorrencia"
                type="text"
                class="form-control"
                maxlength="100"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Observações</label>
              <textarea
                v-model="fastSecretariaTipoOcorrenciaEditar.obs"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2 text-center">
              <label>Aceita anexo</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-model="fastSecretariaTipoOcorrenciaEditar.aceita_anexo"
                        checked="checked"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2 text-center">
              <label>Notifica responsável</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-model="fastSecretariaTipoOcorrenciaEditar.notifica_responsavel"
                        checked="checked"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarTipoOcorrencia()"
                >
                  Confirmar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirTipoOcorrencia"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir ocorrência?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirTipoOcorrencia')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirTipoOcorrencia()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoSecretariaOcorrencias",
  components: {},

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // ocorrencia
      fastSecretariaTipoOcorrencias: [],
      fastSecretariaTipoOcorrenciasLoading: true,
      fastSecretariaTipoOcorrenciaNovo: {
        id_plataforma: 0,
        id_tipo_ocorrencia: 0,
        nome_ocorrencia: "",
        obs: "",
        aceita_anexo: "",
        notifica_responsavel: false
      },
      fastSecretariaTipoOcorrenciaEditar: {
        id_plataforma: 0,
        id_tipo_ocorrencia: 0,
        nome_ocorrencia: "",
        obs: "",
        aceita_anexo: "",
        notifica_responsavel: false
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Tipo ocorrencias
            this.getSecretariaTipoOcorrencias(this.$route.params.id_plataforma);
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    getSecretariaTipoOcorrencias(id_plataforma){
      this.fastSecretariaTipoOcorrenciasLoading = true
      this.promiseGetFastApi("api/fast_secretaria_tipo_ocorrencia/lista", "id_plataforma=" + id_plataforma).then(obj => {
        console.log("getSecretariaTipoOcorrencias", obj);
        if (obj.length) this.fastSecretariaTipoOcorrencias = obj;
        else this.fastSecretariaTipoOcorrencias = []

        this.fastSecretariaTipoOcorrenciasLoading = false
      }).catch(e => {
        console.log(e);
        this.fastSecretariaTipoOcorrenciasLoading = false
      })
    },
    async criarSecretariaTipoOcorrencia() {
      this.fastSecretariaTipoOcorrenciaNovo.id_plataforma = this.$route.params.id_plataforma;
      let erros = []

      this.fastSecretariaTipoOcorrenciaNovo.aceita_anexo = this.fastSecretariaTipoOcorrenciaNovo.aceita_anexo ? true : false
      this.fastSecretariaTipoOcorrenciaNovo.notifica_responsavel = this.fastSecretariaTipoOcorrenciaNovo.notifica_responsavel ? true : false

      if (!this.fastSecretariaTipoOcorrenciaNovo.nome_ocorrencia) erros.push("O nome do ocorrência é obrigatório")      

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        console.log("fastSecretariaTipoOcorrenciaNovo", this.fastSecretariaTipoOcorrenciaNovo)
        this.promiseInserirFastApi(this.fastSecretariaTipoOcorrenciaNovo, "fast_secretaria_tipo_ocorrencia").then((res) => {
          console.log("criarSecretariaTipoOcorrencia", res)
          this.exibeToasty("ocorrencia criado com sucesso", "success");
          this.hideModal("modalCriarTipoOcorrencia");
          this.fastSecretariaTipoOcorrenciaNovo = {
            id_plataforma: 0,
            id_tipo_ocorrencia: 0,
            nome_ocorrencia: "",
            obs: "",
            aceita_anexo: "",
            preco_servico: "",
            notifica_responsavel: false
          }
          // Tipo ocorrencias
          this.fastSecretariaTipoOcorrencias = res;
        }).catch((e) => {
          this.exibeToasty("Erro ao criar requisito", "error");
        });
      }      
    },
    exibeModalEditarTipoOcorrencia(ocorrencia) {
      this.fastSecretariaTipoOcorrenciaEditar = ocorrencia;
      this.showModal("modalEditarTipoOcorrencia");
    },
    async editarTipoOcorrencia() {
      let erros = []

      this.fastSecretariaTipoOcorrenciaEditar.aceita_anexo = this.fastSecretariaTipoOcorrenciaEditar.aceita_anexo ? true : false
      this.fastSecretariaTipoOcorrenciaEditar.notifica_responsavel = this.fastSecretariaTipoOcorrenciaEditar.notifica_responsavel ? true : false

      if (!this.fastSecretariaTipoOcorrenciaEditar.nome_ocorrencia) erros.push("O nome do ocorrência é obrigatório")      

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
       this.$store.state.fastCarregando = true;
        this.promiseAtualizarFastApi(this.fastSecretariaTipoOcorrenciaEditar, "fast_secretaria_tipo_ocorrencia").then(e => {
          this.exibeToasty("Salvo com sucesso", "success");
          this.$store.state.fastCarregando = false;
          this.hideModal("modalEditarTipoOcorrencia");
        }).catch(e => {
          this.exibeToasty("Erro ao salvar alterações", "error");
          this.$store.state.fastCarregando = false;
        }
        );
      }
    },
    exibeModalExcluirTipoOcorrencia(ocorrencia) {
      this.fastSecretariaTipoOcorrenciaEditar = ocorrencia;
      this.showModal("modalExcluirTipoOcorrencia");
    },
    async excluirTipoOcorrencia(){
      this.promiseExcluirFastApi(this.fastSecretariaTipoOcorrenciaEditar, "fast_secretaria_tipo_ocorrencia").then(e => {
        this.exibeToasty("ocorrencia excluído com sucesso", "success");        
        this.getSecretariaTipoOcorrencias(this.$route.params.id_plataforma);   
        this.hideModal("modalExcluirTipoOcorrencia");
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.$store.state.fastCarregando = false;
      });
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
